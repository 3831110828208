import React, {Component} from 'react';
import logo from '../../assets/img/white.png'
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from "firebase/auth";
import {withRouter} from 'react-router-dom'
import * as validator from 'validator';
import {Redirect} from 'react-router-dom';

class Auth extends Component {

  state = {
    sent: false,
    app: null,
    loading: false,
    email: "",
    redirect: false
  }

  componentDidMount() {
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      this.setState({loading: true})
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please re-type your email for confirmation');
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          if (!this.props.internal){
            this.setState({redirect: true})
          }
        })
        .catch((error) => {
          alert(error.message)
        });
    }
    else if (!this.props.internal){
      const appId = this.props.match.params.appId;
      this.loadApp(appId)
    }
  }

  signIn = () => {
    const actionCodeSettings = {
      url: this.props.internal ? window.location.href : "https://auth.mverse.app/auth/" + this.state.appId + "/?finishSignIn=" + encodeURIComponent(this.state.email),
      // This must be true.
      handleCodeInApp: true
    };
    const auth = getAuth();
    sendSignInLinkToEmail(auth, this.state.email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', this.state.email);
        this.setState({sent: true})
      })
      .catch((error) => {
        alert(error.message)
      });
  };

  loadApp = async appId => {
    await this.setState({loading: true})
    const db = getFirestore();
    const docRef = doc(db, "apps", appId);
    const appDoc = await getDoc(docRef);
    if (appDoc.exists()) {
        localStorage.setItem("currentAppId", appId)
      await this.setState({app: appDoc.data(), appId, loading: false})
      onAuthStateChanged(getAuth(), user => {
        if (user){
          this.setState({redirect: true})
        }
      })
      return appDoc.data();
    }
    else{
      await this.setState({loading: false})
    }
    return
  };

  render() {
    if (this.state.redirect){
      return <Redirect to={'/approve'}/>
    }
    return (
      <section className="vh-100" style={{backgroundColor: this.state.sent?"forestgreen":"#508bfc"}}>
        {!this.state.loading?(
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                {this.state.app||this.props.internal?(
                  <div className={'text-center mb-4'}>
                    <img src={this.props.internal?logo:this.state.app.icon} width='125px' alt={this.props.internal?'':this.state.app.name} className={''}/>
                  </div>
                ):null}
                <div className="card shadow-2-strong" style={{borderRadius: "1rem"}}>

                  {this.state.app||this.props.internal?(
                    <>
                      {this.state.sent?(
                        <div className="card-body p-5 text-center">

                          <h3 className="display-6">Check your email!</h3>
                          <p className={"mb-2 text-muted"}>
                            Click the magic link we sent you to complete your sign in.
                          </p>
                          {!this.props.internal?(
                            <p className={"mb-5 text-muted"}>
                              You'll be on your way to <strong>{this.state.app.name}</strong> in no time.
                            </p>
                          ):null}
                        </div>
                      ):(
                        <div className="card-body p-5 text-center">

                          {this.state.app&&!this.state.app.verified?(
                            <div className={'alert alert-warning'}>
                              <strong>{this.state.app.name} hasn't been reviewed yet</strong><br/>You can continue, but make sure you trust the developer.
                            </div>
                          ):null}
                          <h3 className="display-6">{this.props.internal?'My Account':'Sign In'}</h3>
                          {this.props.internal?(
                            <p className={"mb-5 text-muted"}>Sign in using your Authblox email address to continue to your account settings.</p>
                          ):(
                            <p className={"mb-5 text-muted"}><strong>{this.state.app.name}</strong> (by {this.state.app.developer}) partners with Authblox to safely sign you in. Authblox makes it easy for apps to verify who you are on Roblox.</p>
                          )}

                          <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="typeEmailX-2">What's your email?</label>
                            <input
                              value={this.state.email}
                              onChange={e => {
                                this.setState({email: e.target.value})
                              }}
                              placeholder={'Email Address...'} type="email" id="typeEmailX-2" className="form-control form-control-lg"/>
                          </div>

                          {!this.props.internal?(
                            <a href={this.state.app.redirect_url + "/?result=denied&reason=cancelled"}>
                              <button className="btn btn-link btn-lg btn-block" type="button">Cancel</button>
                            </a>
                          ):null}

                          <button onClick={this.signIn} disabled={!this.state.email||!validator.isEmail(this.state.email)} className="btn btn-primary btn-lg btn-block" type="submit">Send a Magic Link</button>
                        </div>
                      )}
                    </>
                  ):(
                    <div className="card-body p-5 text-center">

                      <h3 className="display-6">Unknown app ID</h3>
                      <p className={"mb-2 text-muted"}>
                        The URL you followed might be broken or incorrect.
                      </p>
                    </div>
                  )}

                </div>
                <div className={'text-center mt-5'}>
                  {!this.props.internal?(
                    <>
                      <img src={logo} width="72px"/>
                      <p className={'text-white fw-bold'}>Powered by <strong>Authblox</strong></p>
                    </>
                  ):null}
                  <p className={'text-white'}>&copy; 2021 Melon, Inc.</p>
                  <p className={'text-white'}>
                    <a className={'text-white'} href='/privacy' target='_blank'>Privacy</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

        ):null}
      </section>
    );
  }

}

export default withRouter(Auth);