import React, {Component} from 'react';
import logo from '../../assets/img/white.png'
import emailIcon from '../../assets/img/email.png';
import robloxIcon from '../../assets/img/roblox.png';
import {collectionGroup, query, where, getDocs, getFirestore, collection} from "firebase/firestore";
import AppEntry from "../../components/AppEntry/AppEntry";
import {Link} from "react-router-dom";

class MyApps extends Component {

  state = {
    approved: false,
    devApps: []
  }

  componentDidMount() {
    this.loadMyApps();
  }

  loadMyApps = async () => {
    const db = getFirestore();
    const q = query(collection(db, "apps"), where("ownerId", "==", this.props.user.uid));
    const querySnapshot = await getDocs(q);
    const devApps = [];
    querySnapshot.forEach(doc => {
      devApps.push({
        id: doc.id,
        ...doc.data()
      })
    })
    this.setState({devApps})
  };

  render() {
    return (
      <section className="vh-100" style={{backgroundColor: this.state.sent?"forestgreen":"#508bfc"}}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <p className={'text-white fw-bold'}>Hello, <strong>{this.props.user.email}</strong>!</p>
              <div className="card shadow-2-strong" style={{borderRadius: "1rem"}}>
                <div className="card-body p-5 text-center">

                  <h3 className="display-6">My Apps</h3>
                  <p className={"mb-5 text-muted"}>
                    Apps you've registered to use with Authblox
                  </p>
                  {this.state.devApps.length > 0 ? (
                    <>
                      {this.state.devApps.map(app => {
                        return <AppEntry user={this.props.user} app={app} key={app.id} owned/>
                      })}
                    </>
                  ) : (
                    <div className={'alert alert-info'}>
                      You haven't made any apps yet!
                    </div>
                  )}
                  <Link to={'/apps/new'}>
                    <button className={'btn btn-link'}>
                      Register a new Authblox App
                    </button>
                  </Link>
                  <Link to={'/apps'}>
                    <button className={'btn btn-link'}>
                      View the apps I've linked already
                    </button>
                  </Link>
                </div>

              </div>
              <div className={'text-center mt-5'}>
                <img src={logo} width="72px"/>
                <p className={'text-white fw-bold'}>Powered by <strong>Authblox</strong></p>
                <p className={'text-white'}>&copy; 2021 Melon, Inc.</p>
                <p className={'text-white'}>
                  <a className={'text-white'} href='/privacy' target='_blank'>Privacy</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}

export default MyApps;