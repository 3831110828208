import React, {Component} from 'react';
import logo from '../../assets/img/white.png'
import emailIcon from '../../assets/img/email.png';
import robloxIcon from '../../assets/img/roblox.png';
import {collectionGroup, query, where, getDocs, getFirestore} from "firebase/firestore";
import AppEntry from "../../components/AppEntry/AppEntry";
import {Link} from "react-router-dom";

class Apps extends Component {

  state = {
    approved: false,
    authorizedApps: []
  }

  componentDidMount() {
    this.loadLinkedApps();
  }

  loadLinkedApps = async () => {
    const db = getFirestore();
    const authorizations = query(collectionGroup(db, "authorizations"), where("userId", "==", this.props.user.uid));
    const querySnapshot = await getDocs(authorizations);
    const authorizedApps = [];
    querySnapshot.forEach(doc => {
      authorizedApps.push(doc.data())
    })
    this.setState({authorizedApps})
  };

  render() {
    return (
      <section className="vh-100" style={{backgroundColor: this.state.sent?"forestgreen":"#508bfc"}}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <p className={'text-white fw-bold'}>Hello, <strong>{this.props.user.email}</strong>!</p>
              <div className="card shadow-2-strong" style={{borderRadius: "1rem"}}>
                <div className="card-body p-5 text-center">

                  <h3 className="display-6">Linked Apps</h3>
                  <p className={"mb-5 text-muted"}>
                    You've linked these apps with Authblox.
                  </p>
                  {this.state.authorizedApps.length > 0 ? (
                    <>
                      {this.state.authorizedApps.map(app => {
                        return <AppEntry user={this.props.user} app={app} key={app.appId}/>
                      })}
                    </>
                  ) : (
                    <div className={'alert alert-info'}>
                      No apps linked yet! As you sign in with Authblox, they'll show up here.
                    </div>
                  )}
                  <Link to={'/apps/mine'}>
                    <button className={'btn btn-link'}>Show me my apps instead</button>
                  </Link>
                </div>

              </div>
              <div className={'text-center mt-5'}>
                <img src={logo} width="72px"/>
                <p className={'text-white fw-bold'}>Powered by <strong>Authblox</strong></p>
                <p className={'text-white'}>&copy; 2021 Melon, Inc.</p>
                <p className={'text-white'}>
                  <a className={'text-white'} href='/privacy' target='_blank'>Privacy</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}

export default Apps;