import React from 'react';
import {doc, getDoc, getFirestore, deleteDoc} from "firebase/firestore";
import darkLogo from '../../assets/img/dark.png';
import moment from "moment";
import {Link} from "react-router-dom";

class AppEntry extends React.Component {

  state = {
    meta: null,
    loading: false
  }

  componentDidMount() {
    if (this.props.owned){
      this.setState({
        meta: this.props.app
      })
    }
    else{
      this.loadAppInfo(this.props.app.appId);
    }
  }

  revokeApp = async () => {
    if (window.confirm("Revoke this application? It'll lose access to all granted permissions immediately. This may impact your ability to sign in and use this application.")){
      await deleteDoc(doc(getFirestore(), "apps", this.props.app.appId, "authorizations", this.props.user.uid));
      window.location.reload();
    }
  }

  loadAppInfo = async appId => {
    await this.setState({loading: true})
    const db = getFirestore();
    const docRef = doc(db, "apps", appId);
    const appDoc = await getDoc(docRef);
    this.setState({
      loading: false,
      meta: appDoc.data()
    })
  };

  render() {
    return (
      <div className={'row text-start'}>
        <div className={'col-2'}>
          <img src={this.state.meta?this.state.meta.icon:darkLogo} width='100%'/>
        </div>
        <div className={'col-10'}>
          <h4>{this.state.meta?this.state.meta.name:"..."}</h4>
          <p className={'text-muted'}>
            {this.state.meta?(
              <>
                {this.props.owned?(
                  <>
                    <input
                      type={'text'}
                      className={'form-control'}
                      readOnly
                      value={'auth.mverse.app/auth/' + this.props.app.id}
                    />
                    <Link to={'/apps/' + this.props.app.id}>
                      Configure, manage secrets, and setup...
                    </Link>
                  </>
                ):(
                  <>
                    by <strong>{this.state.meta.developer}</strong>
                    <br/>Linked {moment(this.props.app.createdAt.seconds*1000).calendar()}
                    <br/>Can access: {this.props.app.scopes.join(', ')} (<a onClick={e => {
                      e.preventDefault();
                      this.revokeApp();
                  }} href={'#'} className={'text-danger'}>Revoke</a>)
                  </>
                )}

              </>
            ):null}
          </p>
          {/*<button className="btn btn-link btn-lg btn-block" type="button">*/}
          {/*  Revoke*/}
          {/*</button>*/}

        </div>
      </div>
    );
  }

}

export default AppEntry;