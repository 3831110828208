import React, {Component} from 'react';
import logo from '../../assets/img/white.png'
import codeIcon from '../../assets/img/secretcode.png';
import robloxIcon from '../../assets/img/roblox.png';
import { doc, setDoc, getFirestore, onSnapshot, deleteDoc } from "firebase/firestore";
import moment from "moment";
import {Redirect} from "react-router-dom";

class Verify extends Component {

  state = {
    approved: false,
    pendingVerification: null,
    hovering: false,
    redirectVerify: false
  }

  componentDidMount() {
    this.createVerificationRequest();
    this.fetchVerificationRequest();
    const unsub = onSnapshot(doc(getFirestore(), "identities", this.props.user.uid), (iDoc) => {
      if (iDoc.exists()){
        this.setState({redirectVerify: true});
      }
    });
  }

  fetchVerificationRequest = async() => {
    const unsub = onSnapshot(doc(getFirestore(), "pending_verifications", this.props.user.uid), (vDoc) => {
      this.setState({pendingVerification: vDoc.exists() ? vDoc.data() : null});
      if (vDoc.exists() && moment(vDoc.data().createdAt.seconds*1000).isBefore(moment().subtract(2, 'minutes'))){
        deleteDoc(doc(getFirestore(), "pending_verifications", this.props.user.uid)).then(r => {
          this.createVerificationRequest()
        });
      }
    });
  };

  createVerificationRequest = async () => {
    await setDoc(doc(getFirestore(), "pending_verifications", this.props.user.uid), {
      ownerId: this.props.user.uid,
      email: this.props.user.email,
      createdAt: new Date(),
      words: []
    });
  }

  render() {
    if (this.state.redirectVerify){
      return <Redirect to={'/approve'}/>;
    }
    return (
      <section className="vh-100" style={{backgroundColor: this.state.sent?"forestgreen":"#508bfc"}}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card shadow-2-strong" style={{borderRadius: "1rem"}}>
                <div className="card-body p-5 text-center">

                  <h3 className="display-6">Add Roblox Account</h3>
                  <p className={"mb-5 text-muted"}>
                    Authblox makes it easy for apps to verify who you are on Roblox. Link a Roblox account to continue.
                  </p>
                  <div className={'row text-start'}>
                    <div className={'col-2'}>
                      <img src={codeIcon} width='100%'/>
                    </div>
                    <div className={'col-10'}>
                      <h4>Copy your secret code</h4>
                      <p className={'text-muted'}>
                        Copy your code or write it down! You'll need it in a moment.
                      </p>
                      <textarea
                        className={'form-control form-control-lg'}
                        readOnly
                        rows={4}
                        placeholder="Fetching code..."
                        value={this.state.pendingVerification&&this.state.pendingVerification.words&&this.state.pendingVerification.words.join(' ')}
                      />
                    </div>
                  </div>
                  <div className={'row mt-3 text-start'}>
                    <div className={'col-2'}>
                      <img src={robloxIcon} width='100%'/>
                    </div>
                    <div className={'col-10'}>
                      <h4>Launch our Roblox game</h4>
                      <p className={'text-muted'}>
                        Launch our Roblox game and enter your code - then you're all set!
                      </p>
                      <a href={'https://www.roblox.com/games/7883964339/Authblox'} target={'_blank'} className="btn btn-success btn-lg btn-block" type="button">Visit Game Page</a>
                    </div>
                  </div>

                </div>
              </div>
              <div className={'text-center mt-5'}>
                <img src={logo} width="72px"/>
                <p className={'text-white fw-bold'}>Powered by <strong>Authblox</strong></p>
                <p className={'text-white'}>&copy; 2021 Melon, Inc.</p>
                <p className={'text-white'}>
                  <a className={'text-white'} href='/privacy' target='_blank'>Privacy</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}

export default Verify;