import React, {Component} from 'react';
import logo from '../../assets/img/white.png'
import emailIcon from '../../assets/img/email.png';
import robloxIcon from '../../assets/img/roblox.png';
import {
  collectionGroup,
  query,
  where,
  getDocs,
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  doc,
  getDoc, onSnapshot
} from "firebase/firestore";
import AppEntry from "../../components/AppEntry/AppEntry";
import {Link, Redirect, withRouter} from "react-router-dom";
import * as validator from 'validator';

class RegisterApp extends Component {

  state = {
    editing: null,
    editingId: null,
    error: null,
    name: "",
    developer: "",
    icon: "",
    redirectUrl: "",
    redirect: null,
    loading: false
  }

  componentDidMount() {
    if (this.props.match.params.appId){
      this.loadForEditing(this.props.match.params.appId)
    }
  }

  loadForEditing = async appId => {
    const db = getFirestore();
    const docRef = doc(db, "apps", appId);
    const appDoc = await getDoc(docRef);
    if (appDoc.data().ownerId === this.props.user.uid){
      this.setState({
        loading: false,
        editing: appDoc.data(),
        editingId: appId,
        name: appDoc.data().name,
        developer: appDoc.data().developer,
        icon: appDoc.data().icon,
        redirectUrl: appDoc.data().redirect_url
      })
    }else{
     this.setState({
       redirectToList: true
     })
    }
  };

  generateNewSecret = async () => {

    try {
      const requestRef = await addDoc(collection(getFirestore(), "pending_secrets"), {
        appId: this.state.editingId,
        ownerId: this.props.user.uid
      });
      this.setState({secret: "Generating..."});

      try {
        this.secretUnsub = onSnapshot(doc(getFirestore(), "pending_secrets", requestRef.id), (iDoc) => {
          if (iDoc.exists() && iDoc.data().created) {
            this.setState({secret: iDoc.data().secret});
          }
        });
      }catch(e){
        alert("Read failed: Could not parse response.")
        return;
      }
    }catch (e){
      alert("Write failed: Could not submit security request.");
      return;
    }



  };

  registerApp = async e => {
    e.preventDefault();
    await this.setState({error: false, loading: true})
    try {
      if (this.state.name.length < 3) {
        throw new Error("App name should be at least 3 characters.")
      }
      if (this.state.developer.length < 6){
        throw new Error("Name or company should be at least 6 characters.");
      }
      if (!validator.isURL(this.state.icon)){
        throw new Error("Icon isn't a valid URL");
      }
      if (!validator.isURL(this.state.redirectUrl)){
        throw new Error("Redirect URL is not a valid URL")
      }

      if (this.state.editingId){


        const updateDocRef = doc(getFirestore(), "apps", this.state.editingId);

        await updateDoc(updateDocRef, {
          name: this.state.name,
          developer: this.state.developer,
          icon: this.state.icon,
          redirect_url: this.state.redirectUrl,
        })
        this.setState({loading: false, error: null})

      }
      else{
        const docRef = await addDoc(collection(getFirestore(), "apps"), {
          name: this.state.name,
          developer: this.state.developer,
          icon: this.state.icon,
          redirect_url: this.state.redirectUrl,
          verified: false,
          ownerId: this.props.user.uid,
          permissions: ['email', 'roblox']
        });
        this.setState({redirect: docRef.id, loading: false})
      }




    }catch (e){
      this.setState({
        error: e.message,
        loading: false
      })
    }
  }

  render() {
    if (this.state.redirectToList){
      return <Redirect to={'/apps'}/>
    }
    if (this.state.redirect){
      return <Redirect to={'/apps/mine'}/>
    }
    return (
      <section style={{backgroundColor: this.state.sent ? "forestgreen" : "#508bfc", minHeight: '100vh'}}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6">
              {this.state.icon ? (
                <div className={'text-center mb-4'}>
                  <img src={this.state.icon} width='125px' alt={this.state.name || ''} className={''}/>
                </div>
              ) : null}
              <div className="card shadow-2-strong" style={{borderRadius: "1rem"}}>
                <div className="card-body p-5 text-center">
                  <h3 className="display-6">{this.state.editingId?'Edit':'Register'}{this.state.name ? ': ' + this.state.name : ' App'}</h3>
                  {!this.state.editingId?(
                    <p className={"text-muted"}>
                      Register your app to use it with Authblox
                    </p>
                  ):null}

                  {this.state.error?<div className={'alert alert-danger'}>{this.state.error}</div>:null}
                  <form className={'mt-5'} onSubmit={this.registerApp}>
                    <input
                      disabled={this.state.editing&&this.state.editing.verified}
                      type={'text'}
                      placeholder={'App name...'}
                      className={'form-control'}
                      value={this.state.name}
                      onChange={e => {
                        this.setState({name: e.target.value})
                      }}
                      maxLength={24}
                    />
                    <input
                      type={'text'}
                      disabled={this.state.editing&&this.state.editing.verified}
                      placeholder={'Your name or company...'}
                      className={'form-control mt-2'}
                      value={this.state.developer}
                      onChange={e => {
                        this.setState({developer: e.target.value})
                      }}
                      maxLength={36}
                    />
                    <input
                      type={'url'}
                      placeholder={'Image URL for Logo / App Icon...'}
                      className={'form-control mt-2'}
                      value={this.state.icon}
                      onChange={e => {
                        this.setState({icon: e.target.value})
                      }}
                    />
                    <input
                      type={'url'}
                      placeholder={'Redirct URL...'}
                      className={'form-control mt-2'}
                      value={this.state.redirectUrl}
                      onChange={e => {
                        this.setState({redirectUrl: e.target.value})
                      }}
                    />
                    {this.state.editing?(
                      <>
                        {this.state.editing.verified?(
                          <div className={'text-center text-muted mt-2'}>
                            <strong>To rename your app or change developer info, <a href={'mailto:hi@melonverse.com'} target='_blank'>contact us</a>.</strong>
                          </div>
                        ):(
                          <div className={'alert alert-warning text-center mt-2'}>
                            {this.state.editing.maxUsers?(
                              <>
                                <strong>Users can't sign in.</strong>
                                <br/>You have reached the user limit. Submit your app for review to remove this limitation.
                              </>
                            ):(
                              <>
                                <strong>Your app still needs review.</strong>
                                <br/>Authentication will pause once you reach 100 users.
                              </>
                            )}

                            <br/>
                            <br/><a href={'https://forms.gle/ZfMuXJcj22q5fJur9'} target={'_blank'}>Submit for Review</a>
                          </div>
                        )}
                      </>

                    ):(
                      <div className={'alert alert-warning text-center mt-2'}>
                        <strong>Un-reviewed apps are limited to 100 users.</strong>
                        <br/>Submit your app for review to have this limitation removed.
                      </div>
                    )}

                    <button
                      type='submit'
                      disabled={!this.state.name || !this.state.developer || !this.state.icon || !this.state.redirectUrl || this.state.loading}
                      className={'mt-5 btn btn-success btn-block'}>
                      {this.state.editingId?'Save':'Register'} App
                    </button>
                  </form>

                  {this.state.editing?(
                    <div className={'mt-5'}>
                      <h3 className="display-6">
                        Details for Devs
                      </h3>
                      <p className={'text-muted py-4'}>Looking for docs? <a href='https://melonverse.notion.site/Authblox-Docs-9464a1ce7098405b96d3b598ce508645' target='_blank'>View them here...</a></p>
                      <table className={'text-start table'}>
                        <tbody>
                        <tr>
                          <th>App ID</th>
                          <td>
                            <input type={'text'} readOnly className={'form-control'} value={this.state.editingId}/>
                          </td>
                        </tr>
                        <tr>
                          <th>Owner ID</th>
                          <td>
                            <input type={'text'} readOnly className={'form-control'} value={this.state.editing.ownerId}/>
                          </td>
                        </tr>
                        <tr>
                          <th>Login URL</th>
                          <td>
                            <input type={'text'} readOnly className={'form-control'} value={'auth.mverse.app/auth/' + this.state.editingId}/>
                          </td>
                        </tr>
                        <tr>
                          <th>App Secret</th>
                          <td>
                            {this.state.secret?(
                              <>
                                <input type={'text'} readOnly className={'form-control'} value={this.state.secret}/>
                                <p className={'text-muted'}>This will only be shown once for your security.</p>
                              </>
                            ):(
                              <button onClick={this.generateNewSecret} className={'btn btn-link'}>
                                Regenerate & View
                              </button>
                            )}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  ):null}

                  <br/>
                  <Link to={'/apps/mine'}>
                    <button className={'btn btn-link'}>
                      Nevermind, go back
                    </button>
                  </Link>
                </div>

              </div>
              <div className={'text-center mt-5'}>
                <img src={logo} width="72px"/>
                <p className={'text-white fw-bold'}>Powered by <strong>Authblox</strong></p>
                <p className={'text-white'}>&copy; 2021 Melon, Inc.</p>
                <p className={'text-white'}>
                  <a className={'text-white'}
                     href='/privacy'
                     target='_blank'>Privacy</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}

export default withRouter(RegisterApp);