import logo from './assets/img/white.png';
import './App.css';
import Auth from "./containers/auth/Auth";
import {Switch, Route} from 'react-router-dom';
import Approve from "./containers/auth/Approve";
import Verify from "./containers/auth/Verify";
import Apps from "./containers/auth/Apps";
import robloxIcon from "./assets/img/roblox.png";
import React from "react";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import MyApps from "./containers/auth/MyApps";
import RegisterApp from "./containers/auth/RegisterApp";
import Privacy from "./containers/Privacy";

class App extends React.Component {

  state = {
    user: null
  }

  componentDidMount() {
    onAuthStateChanged(getAuth(), user => {
      this.setState({user})
    })
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route path={'/auth/:appId'} component={Auth}/>
          <Route path={'/approve'} render={() => {
            return this.state.user ? <Approve user={this.state.user}/> : null;
          }}/>
          <Route path={'/verify'} render={() => {
            return this.state.user ? <Verify user={this.state.user}/> : null;
          }}/>
          <Route path={'/apps/new'} render={() => {
            return this.state.user ? <RegisterApp user={this.state.user}/> : <Auth internal/>
          }}/>
          <Route path={'/apps/mine'} render={() => {
            return this.state.user ? <MyApps user={this.state.user}/> : <Auth internal/>
          }}/>
          <Route path={'/apps/:appId'} render={() => {
            return this.state.user ? <RegisterApp user={this.state.user}/> : <Auth internal/>
          }}/>
          <Route path={'/apps'} render={() => {
            return this.state.user ? <Apps user={this.state.user}/> : <Auth internal/>
          }}/>
          <Route path={'/privacy'} component={Privacy}/>
          <Route path={'/'} render={() => {
            window.location.replace("https://authblox.melonverse.com")
            return null;
          }}/>
        </Switch>
      </div>
    );
  }


}

export default App;
