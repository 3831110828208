import emailIcon from '../assets/img/email.png';
import robloxIcon from '../assets/img/roblox.png';
import discordIcon from '../assets/img/Discord.png';

export const scopes = {
    email: {
        icon: emailIcon,
        displayName: "Your email address",
        getIdentityInfo: (user, identity) => {
            return user.email;
        }
    },
    roblox: {
        icon: robloxIcon,
        displayName: "Your linked Roblox account",
        getIdentityInfo: (user, identity) => {
            return identity.user.username;
        }
    },
    discord: {
        icon: discordIcon,
        displayName: "Your linked Discord account",
        getIdentityInfo: (user, identity) => {
            return identity.user.username;
        }
    }
}