import React, {Component} from 'react';
import logo from '../../assets/img/white.png'
import emailIcon from '../../assets/img/email.png';
import robloxIcon from '../../assets/img/roblox.png';
import {doc, getDoc, getFirestore, collection, addDoc} from "firebase/firestore";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {Redirect} from "react-router-dom";
import {scopes} from '../../util/scopes';

class Approve extends Component {

    state = {
        approved: false,
        app: null,
        identity: null,
        appId: null
    }

    componentDidMount() {
        this.loadIdentity().then(() => {
            this.loadApp(window.localStorage.getItem("currentAppId"))
        });
    }

    approveLogin = async () => {
        this.setState({approving: true})
        const data = {
            userId: this.props.user.uid,
            appId: this.state.appId,
            scopes: this.state.app.permissions.map(perm => {
                return perm.toUpperCase();
            }),
            createdAt: new Date()
        }
        const docRef = await addDoc(collection(getFirestore(), "apps/" + this.state.appId + "/pending_authorizations"), data);
        window.location.href = this.state.app.redirect_url + "/?result=approved&authorizationId=" + docRef.id;
        this.setState({approving: false})
    };

    allIdentitesLinked = () => {

      for(let i = 0; i < this.state.app.permissions.length; i++){
        const perm = this.state.app.permissions[i];
        if (perm !== 'email'){
          if (!this.state.identity[perm]){
            return false;
          }
        }
      }

      return true;
    }

    loadApp = async appId => {
        await this.setState({loading: true})
        const db = getFirestore();
        const docRef = doc(db, "apps", appId);
        const appDoc = await getDoc(docRef);
        if (appDoc.exists()) {
            await this.setState({app: appDoc.data(), appId, loading: false})
            return appDoc.data();
        } else {
            await this.setState({loading: false})
        }
        return
    };

    loadIdentity = async () => {
        await this.setState({loading: true})
        const db = getFirestore();
        const docRef = doc(db, "identities", this.props.user.uid);
        const identityDoc = await getDoc(docRef);
        if (identityDoc.exists()) {
            await this.setState({
                identity: {
                    roblox: identityDoc.data(),
                    email: this.props.user.email
                }, loading: false
            })
            return identityDoc.data();
        } else {
            await this.setState({loading: false, identityRedirect: true})
        }
        return;
    };

    render() {

        if (this.state.identityRedirect) {
            return <Redirect to={'/verify'}/>
        }

        if (!this.state.identity || !this.state.app) {
            return null;
        }

        return (
            <section className="min-h-screen" style={{backgroundColor: this.state.sent ? "forestgreen" : "#508bfc"}}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className={'text-center mb-4'}>
                                <img src={this.state.app.icon} width='125px' alt={this.state.app.name}
                                     className={''}/>
                            </div>
                            <div className="card shadow-2-strong" style={{borderRadius: "1rem"}}>
                                {!this.state.app.verified && this.state.app.maxUsers ? (
                                    <div className="card-body p-5 text-center">
                                        <h3 className="display-6">We can't sign you in</h3>
                                        <p className={"mb-5 text-muted"}>
                                            Your Authblox account is working, however, the developer
                                            of {this.state.app.name} needs to take action to re-enable Authblox for
                                            their application. Please let them know that you received this message.
                                            <br/>
                                            <br/>(Error: Max users for unverified app)
                                        </p>
                                        <div className={'mt-4'}>
                                            <a href={this.state.app.redirect_url + "/?result=denied&reason=maxusers"}>
                                                <button className="btn btn-link btn-lg btn-block" type="button">Return
                                                    to application
                                                </button>
                                            </a>
                                        </div>

                                    </div>

                                ) : (
                                    <div className="card-body p-5 text-center">
                                        {this.state.app && !this.state.app.verified ? (
                                            <div className={'alert alert-warning'}>
                                                <strong>{this.state.app.name} hasn't been reviewed yet</strong><br/>You
                                                can continue, but make sure you trust the developer.
                                            </div>
                                        ) : null}
                                        <h3 className="display-6">Sign in to {this.state.app.name}</h3>
                                        <p className={"mb-5 text-muted"}>
                                            You're sharing the following with {this.state.app.developer}:
                                        </p>
                                        {Object.keys(scopes).map(scopeId => {
                                            if (!this.state.app.permissions.includes(scopeId)) {
                                                return null;
                                            }
                                            const scope = scopes[scopeId];
                                            console.log(scopeId, scope);
                                            return (
                                                <div key={scopeId} className={'row text-start'}>
                                                    <div className={'col-2'}>
                                                        <img src={scope.icon} width='100%'/>
                                                    </div>
                                                    <div className={'col-10'}>
                                                        <h4>{scope.displayName}</h4>
                                                      {this.state.identity[scopeId]?(
                                                          <p className={'text-muted'}>
                                                            {scope.getIdentityInfo(this.props.user, this.state.identity[scopeId])}
                                                          </p>
                                                      ):(
                                                          <p className={'text-danger'}>
                                                              Missing identity
                                                          </p>
                                                      )}

                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className={'mt-4'}>
                                            <a href={this.state.app.redirect_url + "/?result=denied&reason=cancelled"}>
                                                <button className="btn btn-link btn-lg btn-block" type="button">Cancel
                                                </button>
                                            </a>
                                          {this.allIdentitesLinked()?(
                                              <button disabled={this.state.approving} onClick={this.approveLogin}
                                                      className="btn btn-success btn-lg btn-block" type="submit">Allow
                                              </button>
                                          ):(
                                              <button disabled
                                                      className="btn btn-danger btn-lg btn-block" type="button">Missing Info
                                              </button>
                                          )}

                                        </div>

                                    </div>
                                )}
                            </div>
                            <div className={'text-center mt-5'}>
                                <img src={logo} width="72px"/>
                                <p className={'text-white fw-bold'}>Powered by <strong>Authblox</strong></p>
                                <p className={'text-white'}>&copy; 2022 Melon, Inc.</p>
                                <p className={'text-white'}>
                                    <a className={'text-white'}
                                       href='/privacy'
                                       target='_blank'>Privacy</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

}

export default Approve;