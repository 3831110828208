export default function Privacy() {
    return <section style={{backgroundColor: "#508bfc", minHeight: "100vh", padding: "4em 0"}}>
        <div
            className="mx-auto w-full bg-white p-5 text-left"
            style={{maxWidth: "1000px", borderRadius: "10px", textAlign: "left"}}
        >
            <div className='text-center my-6'>
                <h2 className='text-3xl font-bold'>Privacy Policy</h2>
                <p className='font-light'>Updated January 18th, 2023</p>
            </div>

            <div>
                <h2 className='text-lg font-bold'>Your privacy matters.</h2>
                <ol className='list-decimal list-outside ml-4'>
                    <li className='my-2'>
                        Thank you for choosing to be part of Authblox, owned and operated by Melon, Inc. ("Melon",
                        "we", "us", "our"). We are committed
                        to protecting your personal information and your right to privacy. If you have any questions or
                        concerns about this privacy policy
                        notice, or our practices with regards to your personal information, please reach out to
                        hi@melonverse.com.
                    </li>
                    <li className='my-2'>
                        When you visit our website at auth.mverse.app (the "Website"), and more generally, use any of
                        our services (the "Services", which
                        include the Website), we appreciate that you are trusting us with your personal information. We
                        take your privacy
                        very seriously. In this privacy notice, we seek to explain to you in the clearest way possible
                        what information we
                        collect, how we use it, and what rights you have in relation to it. We hope you take some time
                        to read through it
                        carefully. If there are any terms in this privacy notice that you do not agree with, please
                        discontinue your use
                        of the Services immediately.
                    </li>
                    <li className='my-2'>
                        Our Privacy Policy applies to all information collected through our Services (which, as
                        described above, includes our
                        Website), as well as, any related services, sales, marketing, or events.
                    </li>
                </ol>
                <h2 className='text-lg font-bold'>Information We Collect</h2>
                <ol className='list-decimal list-outside ml-4' start={4}>
                    <li className='my-2'>
                        <strong>Information you provide to us.</strong>
                        We collect personal information that you voluntarily provide to us when you register on the
                        Website,
                        express an interest in obtaining information about us or our products and Services, when you
                        participate in activities on the Website (such as by posting messages in our online forums or
                        entering competitions, contests or giveaways) or otherwise when you contact us.
                        The personal information that we collect depends on the context of your interactions with us and
                        the Website, the choices you make and the products and features you use. The personal
                        information
                        we collect may include the following: names; email addresses; usernames; passwords; contact
                        preferences;
                        contact or authentication data; billing addresses; and other similar information. We may collect
                        data necessary
                        to process your payment if you make purchases, such as your payment instrument number (such as a
                        credit
                        card number), and the security code associated with your payment instrument. All payment data is
                        stored
                        by Stripe. You may find their privacy notice link(s) here: https://stripe.com/privacy. We may
                        provide you
                        with the option to register with us using your existing social media account details, like your
                        Facebook,
                        Twitter or other social media account. If you choose to register in this way, we will collect
                        the Information
                        described in the section called "Using Social Logins" below. All personal information that you
                        provide to
                        us must be true, complete and accurate, and you must notify us of any changes to such personal
                        information.
                    </li>
                    <li className='my-2'>
                        <strong>Information automatically collected.</strong> We automatically collect certain
                        information when you
                        visit, use or navigate the Website. This information does not reveal your specific identity
                        (like your name
                        or contact information) but may include device and usage information, such as your IP address,
                        browser and
                        device characteristics, operating system, language preferences, referring URLs, device name,
                        country, location,
                        information about how and when you use our Website and other technical information. This
                        information is
                        primarily needed to maintain the security and operation of our Website, and for our internal
                        analytics and
                        reporting purposes. Like many businesses, we also collect information through cookies and
                        similar technologies.
                        The information we collect includes: Log and usage data that is service-related, diagnostic,
                        usage and
                        performance information our servers automatically collect when you access or use our Website and
                        which we
                        record in log files. Depending on how you interact with us, this log data may include your IP
                        address,
                        device information, browser type and settings and information about your activity in the Website
                        (such as
                        the date/time stamps associated with your usage, pages and files viewed, searches and other
                        actions you
                        take such as which features you use), device event information (such as system activity, error
                        reports
                        (sometimes called 'crash dumps') and hardware settings). We collect device data such as
                        information about your computer,
                        phone, tablet or other device you use to access the Website. Depending on the device used, this
                        device data
                        may include information such as your IP address (or proxy server), device and application
                        identification
                        numbers, location, browser type, hardware model Internet service provider and/or mobile carrier,
                        operating
                        system and system configuration information.
                    </li>
                </ol>
                <h2 className='text-lg font-bold'>Using Your Information</h2>
                <ol className='list-decimal list-outside ml-4' start={6}>
                    <li className='my-2'>
                        We use personal information collected via our Website for a variety of business purposes
                        described
                        below. We process your personal information for these purposes in reliance on our legitimate
                        business
                        interests, in order to enter into or perform a contract with you, with your consent, and/or for
                        compliance
                        with our legal obligations. We indicate the specific processing grounds we rely on next to each
                        purpose
                        listed below. We use the information we collect or receive: (a) To facilitate account creation
                        and logon
                        process. If you choose to link your account with us to a third-party account (such as your
                        Google or Facebook
                        account), we use the information you allowed us to collect from those third parties to
                        facilitate account
                        creation and logon process for the performance of the contract. See the section below headed
                        "Using Social Logins"
                        for further information. (b) We post testimonials on our Website that may contain personal
                        information. Prior to
                        posting a testimonial, we will obtain your consent to use your name and the content of the
                        testimonial. If you
                        wish to update, or delete your testimonial, please contact us at hi@melonverse.com and be
                        sure to include
                        your name, testimonial location, and contact information. (c) We may use your information to
                        request feedback
                        and to contact you about your use of our Website. (d) To enable user-to-user communications. We
                        may use your
                        information in order to enable user-to-user communications with each user's consent. (e) We may
                        use your
                        information for the purposes of managing our account and keeping it in working order. (f) We may
                        use your personal information
                        to send you product, service and new feature information and/or information about changes to our
                        terms, conditions,
                        and policies. (g) We may use your information as part of our efforts to keep our Website safe
                        and secure
                        (for example, for fraud monitoring and prevention). (h) To enforce our terms, conditions and
                        policies for business purposes,
                        to comply with legal and regulatory requirements or in connection with our contract. (i) If we
                        receive a subpoena
                        or other legal request, we may need to inspect the data we hold to determine how to respond. (j)
                        We may use your
                        information to fulfill and manage your orders, payments, returns, and exchanges made through the
                        Website.
                        (k) We may use your information to administer prize draws and competitions when you elect to
                        participate in
                        our competitions. (l) We may use your information to provide you with the requested service. (m)
                        We may use your information
                        to respond to your inquiries and solve any potential issues you might have with the use of our
                        Services.
                        (n) We and/or our third-party marketing partners may use the personal information you send to us
                        for our marketing purposes,
                        if this is in accordance with your marketing preferences. For example, when expressing an
                        interest in obtaining
                        information about us or our Website, subscribing to marketing or otherwise contacting us, we
                        will collect personal
                        information from you. You can opt-out of our marketing emails at any time (see the "Privacy
                        Rights" below).
                        (o) We may use your information to develop and display personalized content and advertising (and
                        work with third parties who do so) tailored
                        to your interests and/or location and to measure its effectiveness.
                    </li>
                </ol>
                <h2 className='text-lg font-bold'>Information Confidentiality</h2>
                <ol className='list-decimal list-outside ml-4' start={7}>
                    <li className='my-2'>
                        We may process or share your data that we hold based on the following legal basis:
                        (a) Consent: We may process your data if you have given us specific consent to use your personal
                        information for a specific purpose. (b) Legitimate Interests: We may process your data when it
                        is
                        reasonably necessary to achieve our legitimate business interests. (c) Performance of a
                        Contract: Where
                        we have entered into a contract with you, we may process your personal information to fulfill
                        the terms
                        of our contract. (d) Legal Obligations: We may disclose your information where we are legally
                        required to
                        do so in order to comply with applicable law, governmental requests, a judicial proceeding,
                        court order,
                        or legal process, such as in response to a court order or a subpoena (including in response to
                        public
                        authorities to meet national security or law enforcement requirements). (e) Vital Interests: We
                        may disclose
                        your information where we believe it is necessary to investigate, prevent, or take action
                        regarding potential
                        violations of our policies, suspected fraud, situations involving potential threats to the
                        safety of any person
                        and illegal activities, or as evidence in litigation in which we are involved.
                    </li>
                    <li className='my-2'>
                        More specifically, we may need to process your data or share your personal information in the
                        following
                        situations: (a) Business Transfers. We may share or transfer your information in connection
                        with, or during
                        negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                        portion of our
                        business to another company. (b) Vendors, Consultants and Other Third-Party Service Providers.
                        We may share
                        your data with third-party vendors, service providers, contractors or agents who perform
                        services for us or
                        on our behalf and require access to such information to do that work. Examples include: payment
                        processing,
                        data analysis, email delivery, hosting services, customer service and marketing efforts. We may
                        allow selected
                        third parties to use tracking technology on the Website, which will enable them to collect data
                        on our behalf
                        about how you interact with our Website over time. This information may be used to, among other
                        things, analyze
                        and track data, determine the popularity of certain content, pages or features, and better
                        understand online
                        activity. Unless described in this notice, we do not share, sell, rent or trade any of your
                        information with
                        third parties for their promotional purposes. (c) Affiliates. We may share your information with
                        our affiliates,
                        in which case we will require those affiliates to honor this privacy notice. Affiliates include
                        our parent company
                        and any subsidiaries, joint venture partners or other companies that we control or that are
                        under common control with us.
                        (d) Business Partners. We may share your information with our business partners to offer you
                        certain products, services
                        or promotions. (e) Other Users. When you share personal information (for example, by posting
                        comments, contributions or other
                        content to the Website) or otherwise interact with public areas of the Website, such personal
                        information may be viewed by all users and
                        may be publicly made available outside the Website in perpetuity. If you interact with other
                        users of our Website and register for our Website
                        through a social network (such as Facebook), your contacts on the social network will see your
                        name, profile photo, and descriptions of your activity.
                        Similarly, other users will be able to view descriptions of your activity, communicate with you
                        within our Website, and view your profile.
                    </li>
                    <li className='my-2'>
                        We only share and disclose your information with the following third parties. We have
                        categorized
                        each party so that you may be easily understand the purpose of our data collection and
                        processing practices.
                        If we have processed your data based on your consent and you wish to revoke your consent, please
                        contact us
                        using the contact details provided in the section below titled "Contacting Us". (a) Allow Users
                        to Connect
                        to Their Third-Party Accounts: Google account, Github account, Twitter account and Facebook
                        account
                        (b) Cloud Computing Services: Google Cloud Platform (c) Functionality and Infrastructure
                        Optimization:
                        Cloud Firestore, Cloud Storage for Firebase, Cloud Functions for Firebase and Algolia (d)
                        Invoice
                        and Billing: Stripe (e) Web and Mobile Analytics: Google Analytics (f) Website Performance
                        Monitoring: Sentry
                    </li>
                </ol>
                <h2 className='text-lg font-bold'>Using Social Logins</h2>
                <ol className='list-decimal list-outside ml-4' start={10}>
                    <li className='my-2'>
                        Our Website offers you the ability to register and login using your third-party social media
                        account
                        details (like your Facebook or Twitter logins). Where you choose to do this, we will receive
                        certain
                        profile information about you from your social media provider. The profile Information we
                        receive may
                        vary depending on the social media provider concerned, but will often include your name, email
                        address,
                        friends list, profile picture as well as other information you choose to make public on such
                        social
                        media platform. We will use the information we receive only for the purposes that are described
                        in this
                        privacy notice or that are otherwise made clear to you on the relevant Website. Please note that
                        we do
                        not control, and are not responsible for, other uses of your personal information by your
                        third-party
                        social media provider. We recommend that you review their privacy notice to understand how they
                        collect,
                        use and share your personal information, and how you can set your privacy preferences on their
                        sites and
                        apps.
                    </li>
                </ol>
                <h2 className='text-lg font-bold'>Third Party Websites</h2>
                <ol className='list-decimal list-outside ml-4' start={11}>
                    <li className='my-2'>
                        The Website may contain advertisements from third parties that are not affiliated with us and
                        which
                        may link to other websites, online services or mobile applications. We cannot guarantee the
                        safety
                        and privacy of data you provide to any third parties. Any data collected by third parties is not
                        covered by this privacy notice. We are not responsible for the content or privacy and security
                        practices and policies of any third parties, including other websites, services or applications
                        that may be linked to or from the Website. You should review the policies of such third parties
                        and contact them directly to respond to your questions.
                    </li>
                </ol>
                <h2 className='text-lg font-bold'>Information Retention and Safety</h2>
                <ol className='list-decimal list-outside ml-4' start={12}>
                    <li className='my-2'>
                        We will only keep your personal information for as long as it is necessary for the purposes set
                        out in this privacy notice, unless a longer retention period is required or permitted by law
                        (such as tax, accounting or other legal requirements). No purpose in this notice will require
                        us keeping your personal information for longer than thirty six (36) months past the termination
                        of the user's account. When we have no ongoing legitimate business need to process your personal
                        information, we will either delete or anonymize such information, or, if this is not possible
                        (for example,
                        because your personal information has been stored in backup archives), then we will securely
                        store your
                        personal information and isolate it from any further processing until deletion is possible.
                    </li>
                    <li className='my-2'>
                        We have implemented appropriate technical and organizational security measures designed to
                        protect
                        the security of any personal information we process. However, despite our safeguards and efforts
                        to
                        secure your information, no electronic transmission over the Internet or information storage
                        technology
                        can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                        cybercriminals, or
                        other unauthorized third parties will not be able to defeat our security, and improperly
                        collect, access,
                        steal, or modify your information. Although we will do our best to protect your personal
                        information,
                        transmission of personal information to and from our Website is at your own risk. You should
                        only access
                        the Website within a secure environment.
                    </li>
                </ol>
                <h2 className='text-lg font-bold'>Privacy Rights</h2>
                <ol className='list-decimal list-outside ml-4' start={14}>
                    <li className='my-2'>
                        If you are a resident in the European Economic Area and you believe we are unlawfully processing
                        your personal information, you also have the right to complain to your local data protection
                        supervisory
                        authority. You can find their contact details here:
                        http://ec.europa.eu/justice/data-protection/bodies/authorities/index en.htm.
                        If you are a resident in Switzerland, the contact details for the data protection authorities
                        are
                        available here: https://www.edoeb.admin.ch/edoeb/en/home.html.
                        If you have questions or comments about your privacy rights, you may email us at
                        hi@melonverse.com.
                        <br/>
                        If you would at any time like to review or change the information in your account or terminate
                        your account,
                        you can: (a) Log in to your account settings and update your user account. Upon your request to
                        terminate your account, we will deactivate or delete your account and information from our
                        active
                        databases. However, we may retain some information in our files to prevent fraud, troubleshoot
                        problems,
                        assist with any investigations, enforce our Terms of Use and/or comply with applicable legal
                        requirements.
                        Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you
                        prefer,
                        you can usually choose to set your browser to remove cookies and to reject cookies. If you
                        choose to
                        remove cookies or reject cookies, this could affect certain features or services of our Website.
                        To opt-out of interest-based advertising by advertisers on our Website visit
                        http://www.aboutads.info/choices/.
                        Opting out of email marketing: You can unsubscribe from our marketing email list at any time by
                        clicking on the unsubscribe link in the emails that we send or by contacting us using the
                        details
                        provided below. You will then be removed from the marketing email list — however, we may still
                        communicate with you, for example to send you service-related emails that are necessary for the
                        administration and use of your account, to respond to service requests, or for other
                        non-marketing
                        purposes. To otherwise opt-out, you may: (a) Access your account settings and update your
                        preferences.
                    </li>
                    <li className='my-2'>
                        Most web browsers and some mobile operating systems and mobile applications include a
                        Do-Not-Track ("DNT")
                        feature or setting you can activate to signal your privacy preference not to have data about
                        your online
                        browsing activities monitored and collected. At this stage no uniform technology standard for
                        recognizing
                        and implementing DNT signals has been finalized. As such, we do not currently respond to DNT
                        browser
                        signals or any other mechanism that automatically communicates your choice not to be tracked
                        online.
                        If a standard for online tracking is adopted that we must follow in the future, we will inform
                        you
                        about that practice in a revised version of this privacy notice.
                    </li>
                    <li className='my-2'>
                        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
                        users who
                        are California residents to request and obtain from us, once a year and free of charge,
                        information
                        about categories of personal information (if any) we disclosed to third parties for direct
                        marketing
                        purposes and the names and addresses of all third parties with which we shared personal
                        information
                        in the immediately preceding calendar year. If you are a California resident and would like to
                        make
                        such a request, please submit your request in writing to us using the contact information
                        provided below.
                        If you are under 18 years of age, reside in California, and have a registered account with the
                        Website,
                        you have the right to request removal of unwanted data that you publicly post on the Website. To
                        request
                        removal of such data, please contact us using the contact information provided below, and
                        include the
                        email address associated with your account and a statement that you reside in California. We
                        will make
                        sure the data is not publicly displayed on the Website, but please be aware that the data may
                        not
                        be completely or comprehensively removed from all our systems (e.g. backups, etc.).
                    </li>
                </ol>
                <h2 className='text-lg font-bold'>Wrap Up &amp; Contacting Us</h2>
                <ol className='list-decimal list-outside ml-4' start={17}>
                    <li className='my-2'>
                        We may update this privacy notice from time to time. The updated version will be indicated by an
                        updated "Revised" date and the updated version will be effective as soon as it is accessible.
                        If we make material changes to this privacy notice, we may notify you either by prominently
                        posting
                        a notice of such changes or by directly sending you a notification. We encourage you to review
                        this
                        privacy notice frequently to be informed of how we are protecting your information.
                    </li>
                    <li className='my-2'>
                        If you have questions or comments about this notice, you may email us at hi@melonverse.com
                    </li>
                    <li className='my-2'>
                        Based on the applicable laws of your country, you may have the right to request access to
                        the personal information we collect from you, change that information, or delete it in
                        some circumstances. To request to review, update, or delete your personal information, please
                        visit: https://auth.mverse.app/account. We will respond to your request within 30 days.
                    </li>
                </ol>
            </div>
        </div>
    </section>
}